/* eslint-disable max-classes-per-file */
import * as React from 'react';
import { VisualTheme } from '@inwink/entities/visualtheme';
import { registerItemTemplatePart } from '@inwink/itemtemplate/templatecatalog';
import { withI18nHelper } from '@inwink/i18n/reactcontext';
import { AspectRatio } from '@inwink/responsive/aspectratio';
import { Entities } from '@inwink/entities/entities';
import { parse } from '@inwink/utils/querystring';

import './video.less';
import { ItemTemplateProps } from '@inwink/itemtemplate';

function generateUrl(src: string, video: IVideoConfig, addAutoplay: boolean, addMute: boolean) {
    const regex = new RegExp(/^(https?):\/\//, 'gi'); // new URL() can only be used with protocol
    if (!src || (!addAutoplay && !addMute) || !regex.test(src)) {
        // return { autoPlay: '', mute: '' };
        return src;
    }
    const isTierIframe = !video.dailymotion && !video.youtube;

    const url = new URL(src);
    if (addAutoplay) {
        url.searchParams.set('autoplay', isTierIframe ? '' : '1');
    }
    if (addMute) {
        url.searchParams.set('mute', '1');
    }
    return url.toString();

    // const alreadyHaveQueryString = /\?([\w-]+(=[\w-]*)?(&[\w-]+(=[\w-]*)?)*)?$/.test(src);
    // const autoPlay = `${alreadyHaveQueryString || isTierIframe ? '&' : '?'}${isTierIframe ? 'autoplay' : 'autoplay=1'}`;
    // const mute = `${alreadyHaveQueryString ? '&' : '?'}mute=1`;

    // return { autoPlay, mute };
}

export interface IVideoConfig {
    aspectRatio?: number;
    url?: string | VisualTheme.IAppLabel;
    iframe?: string | VisualTheme.IAppLabel;
    customPlayerUrl?: string | VisualTheme.IAppLabel;
    dailymotion?: { id: string | VisualTheme.IAppLabel };
    youtube?: { id: string | VisualTheme.IAppLabel };
    controls?: boolean;
    autoplay?: boolean;
    loop?: boolean;
    poster?: string;
    preload?: string;

}

export interface ICMSVideoProps {
    displayLanguage: string;
    video: IVideoConfig;
    aspectRatio: number;
    style?: any;
    className?: string;
    i18nHelper?: Entities.i18nHelper;
}

@withI18nHelper()
export class CMSVideo extends React.PureComponent<ICMSVideoProps, any> {
    constructor(props) {
        super(props);
        this.state = {
            isVideoVisible: false
        };
    }

    getVideoUrl() {
        const video = this.props.video;
        const lng = this.props.displayLanguage;

        if (video.url) {
            if (typeof video.url === "string") {
                return video.url;
            }

            return video.url[lng];
        }

        if (video.customPlayerUrl) {
            if (typeof video.customPlayerUrl === "string") {
                return checkCustomPlayerUrl(video.customPlayerUrl);
            }

            return checkCustomPlayerUrl(video.customPlayerUrl[lng]);
        }

        if (video.dailymotion && video.dailymotion.id) {
            if (typeof video.dailymotion.id === 'string') {
                return "//www.dailymotion.com/embed/video/" + video.dailymotion.id;
            } if (video.dailymotion.id[lng]) {
                return "//www.dailymotion.com/embed/video/" + video.dailymotion.id[lng];
            }
        }

        if (video.youtube && video.youtube.id) {
            let url;
            if (typeof video.youtube.id === 'string') {
                url = "https://www.youtube-nocookie.com/embed/" + video.youtube.id;
            } else if (video.youtube.id[lng]) {
                url = "https://www.youtube-nocookie.com/embed/" + video.youtube.id[lng];
            }
            return url;
        }

        if (video.iframe) {
            if (typeof video.iframe === "string") {
                return video.iframe;
            }

            return video.iframe[lng];
        }

        return null;
    }

    startVideo = () => {
        if (!this.state.isVideoVisible) {
            this.setState({ isVideoVisible: true });
        }
    };

    getYouTubeThumbnail() {
        const i18n = this.props.i18nHelper;
        if (this.props.video.youtube && this.props.video.youtube.id) {
            const youtubeId = typeof this.props.video.youtube.id !== "string"
                ? i18n.translateBag(this.props.video.youtube.id as VisualTheme.IAppLabel)
                : this.props.video.youtube.id as string;
            return `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
        }
    }

    renderFrame(width, height) {
        const video = this.props.video;
        if (this.props.video.url) {
            const args = {
                width: width,
                height: height,
                controls: this.props.video.controls !== undefined ? this.props.video.controls : true,
                autoPlay: this.props.video.autoplay !== undefined ? this.props.video.autoplay : undefined,
                loop: this.props.video.loop !== undefined ? this.props.video.loop : undefined,
                poster: this.props.video.poster !== undefined ? this.props.video.poster : undefined,
                preload: this.props.video.preload !== undefined ? this.props.video.preload : undefined,
                src: this.getVideoUrl()
            };
            return React.createElement("video" as any, args);
        }
        const src = this.getVideoUrl();
        // const { autoPlay, mute } = getAttributesFromQueriesString(src, video);
        const computedSrc = generateUrl(
            src,
            video,
            !!this.state.isVideoVisible,
            !!this.props.video.dailymotion
        );

        const args = {
            frameBorder: "0",
            width: width,
            height: height,
            allowFullScreen: "",
            style: { border: "none" },
            allow: this.state.isVideoVisible ? "autoplay" : "",
            src: computedSrc,
            scrolling: "no"
        };
        if (this.props.video.poster) {
            const iFrame = React.createElement("iframe" as any, args);
            const poster = this.props.video.poster;
            return <div className="video-wrapper">
                <div className="iframe-wrapper">
                    {iFrame}

                    {!this.props.video.customPlayerUrl && (
                        <>
                            <div
                                style={{
                                    width: width,
                                    height: width / this.props.aspectRatio
                                }}
                                className={"dark-background " + (this.state.isVideoVisible ? "hidden" : "")}
                            />
                            <span
                                onClick={this.startVideo}
                                className={"video-background " + (this.state.isVideoVisible ? "hidden" : "")}
                                style={{
                                    backgroundImage: "url(\"" + poster + "\")",
                                    width: width,
                                    height: width / this.props.aspectRatio,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    backgroundPosition: "center center",
                                    margin: "0 auto"
                                }}
                            >
                                <div className="player">
                                    <div className="player-button clickable">
                                        <i className="inwink-player-play" />
                                    </div>
                                </div>
                            </span>
                        </>
                    )}
                </div>
            </div>;
        }
        return React.createElement("iframe" as any, args);
    }

    render() {
        let ratio = 1.77;
        if (this.props.aspectRatio) {
            ratio = this.props.aspectRatio;
        }
        return <AspectRatio className={this.props.className} style={this.props.style} ratio={ratio}>
            {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                (width, _height) => (
                    this.renderFrame(width, width / ratio)
                )
            }
        </AspectRatio>;
    }
}

export interface IItemTemplateVideoProps extends ItemTemplateProps {
    i18nHelper?: Entities.i18nHelper;
}

@withI18nHelper()
export class ItemTemplateVideo extends React.PureComponent<IItemTemplateVideoProps, any> {
    constructor(props: IItemTemplateVideoProps) {
        super(props);
        this.state = {
            isVideoVisible: false,
            video: props.data && props.data.video,
            poster: props.data && props.data.picture
        };
    }

    getVideoUrl() {
        const video = this.state.video;
        const lng = this.props.datacontext?.i18nstate?.currentLanguageCode;

        if (video && video.url) {
            if (typeof video.url === "string") {
                return video.url;
            }

            return video.url[lng];
        }

        if (video && video.customPlayerUrl) {
            if (typeof video.customPlayerUrl === "string") {
                return checkCustomPlayerUrl(video.customPlayerUrl);
            }

            return checkCustomPlayerUrl(video.customPlayerUrl[lng]);
        }

        if (video && video.dailymotion && video.dailymotion.id) {
            if (typeof video.dailymotion.id === 'string') {
                return "//www.dailymotion.com/embed/video/" + video.dailymotion.id;
            } if (video.dailymotion.id[lng]) {
                return "//www.dailymotion.com/embed/video/" + video.dailymotion.id[lng];
            }
        }

        if (video && video.vimeo && video.vimeo.id) {
            if (typeof video.vimeo.id === 'string') {
                return "https://player.vimeo.com/video/" + video.vimeo.id;
            } if (video.vimeo.id[lng]) {
                return "https://player.vimeo.com/video/" + video.vimeo.id[lng];
            }
        }

        if (video && video.youtube && video.youtube.id) {
            let url;
            if (typeof video.youtube.id === 'string') {
                url = "https://www.youtube-nocookie.com/embed/" + video.youtube.id;
            } else if (video.youtube.id[lng]) {
                url = "https://www.youtube-nocookie.com/embed/" + video.youtube.id[lng];
            }
            return url;
        }
        if (video.iframe) {
            if (typeof video.iframe === "string") {
                return video.iframe;
            }

            return video.iframe[lng];
        }

        return null;
    }

    startVideo = () => {
        if (!this.state.isVideoVisible) {
            this.setState({ isVideoVisible: true });
        }
    };

    getYouTubeThumbnail() {
        const i18n = this.props.i18nHelper;
        if (this.state.video.youtube && this.state.video.youtube.id) {
            const youtubeId = typeof this.state.video.youtube.id !== "string"
                ? i18n.translateBag(this.state.video.youtube.id as VisualTheme.IAppLabel)
                : this.state.video.youtube.id as string;
            return `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
        }
    }

    renderFrame(width, height) {
        const video = this.state.video;
        if (this.state.video && this.state.video.url) {
            const args = {
                width: width,
                height: height,
                controls: this.state.video.controls !== undefined ? this.state.video.controls : true,
                autoPlay: this.state.video.autoplay !== undefined ? this.state.video.autoplay : undefined,
                loop: this.state.video.loop !== undefined ? this.state.video.loop : undefined,
                poster: this.state.poster !== undefined ? this.state.poster : undefined,
                preload: this.state.video.preload !== undefined ? this.state.video.preload : undefined,
                src: this.getVideoUrl()
            };
            return React.createElement("video" as any, args);
        } if (this.state.video) {
            const src = this.getVideoUrl();
            const computedSrc = generateUrl(
                src,
                video,
                !!this.state.isVideoVisible,
                !!this.state.video && this.state.video.dailymotion
            );

            const args = {
                width: width,
                height: height,
                frameBorder: "0",
                allowFullScreen: "true",
                style: { border: "none" },
                allow: this.state.isVideoVisible ? "autoplay" : "",
                src: computedSrc,
                scrolling: "no",
                referrerpolicy: "no-referrer-when-downgrade"
            };

            if ((this.state.video && this.state.poster)) {
                const iFrame = React.createElement("iframe" as any, args);
                const poster = this.state.poster;
                return <div
                    className="video-wrapper"
                    style={{
                        width: width,
                        height: width / 1.77
                    }}
                >
                    <div
                        className="iframe-wrapper"
                        style={{
                            position: "inherit"
                        }}
                    >
                        {iFrame}
                        <div
                            style={{
                                width: width,
                                height: width / 1.77
                            }}
                            className={"dark-background " + (this.state.isVideoVisible ? "hidden" : "")}
                        />
                        <span
                            onClick={this.startVideo}
                            className={"video-background " + (this.state.isVideoVisible ? "hidden" : "")}
                            style={{
                                backgroundImage: "url(\"" + poster + "\")",
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                                width: width,
                                height: width / 1.77,
                                backgroundPosition: "center center",
                                margin: "0 auto"
                            }}
                        >
                            <div className="player">
                                <div className="player-button clickable">
                                    <i className="inwink-player-play" />
                                </div>
                            </div>
                        </span>
                    </div>
                </div>;
            }
            return React.createElement("iframe" as any, args);
        }
        return "";
    }

    render() {
        const ratio = 1.77;
        return <AspectRatio style={{ width: "100%", height: "100%" }} ratio={ratio}>
            {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                (width, _height) => (
                    this.renderFrame(width, width / ratio)
                )
            }
        </AspectRatio>;
    }
}

export function checkCustomPlayerUrl(customPlayerUrl: string) {
    let result = customPlayerUrl;
    if (result) {
        if (result.indexOf("youtube.com/watch") >= 0) {
            const queryString = result.substring(result.indexOf("?"));
            const args = parse(queryString);
            if (args.v) {
                result = "//www.youtube-nocookie.com/embed/" + args.v;
            }
        } else if (result.indexOf("https://vimeo.com/") >= 0) {
            result = 'https://player.vimeo.com/video/' + result.replace('https://vimeo.com/', '');
        }
    }

    return result;
}

registerItemTemplatePart("videoitem", ItemTemplateVideo);
